import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const ServiceAreaFour = () => {
  // const CustomPrevArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         display: "block",
  //         color: "primary",
  //         borderRadius: "50%",
  //         width: "50px",
  //         height: "50px",
  //         zIndex: 1,
  //       }}
  //       onClick={onClick}
  //     />
  //   );
  // };

  // const CustomNextArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         display: "block",
  //         color: "primary",
  //         borderRadius: "50%",
  //         width: "50px",
  //         height: "50px",
  //         zIndex: 1,
  //       }}
  //       onClick={onClick}
  //     />
  //   );
  // };

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {/*=================== service area start ===================*/}
      <div
        className="service-area bg-cover py-5"
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title text-center">
                <h6 className="sub-title">WHAT WE OFFER</h6>
                <h2 className="title">
                  IT <span>Services</span> provided by us
                </h2>
              </div>
            </div>
          </div>
          <div className="services-carousel" style={{ height: "420px" }}>
            <Slider {...settings}>
              <div
                className="single-work-process-inner"
                style={{ height: "420px" }}
              >
                <div className="thumb mb-3">
                  <img src="assets/icons/webDev.png" alt="img" />
                </div>
                <div className="details">
                  <h5 className="mb-3">Web Development</h5>
                  <p className="content mb-3">
                    We build custom, responsive websites that are visually
                    appealing and optimized for performance. Our solutions focus
                    on enhancing user experience, incorporating SEO best
                    practices, and ensuring security, with scalable options to
                    support your business growth.
                  </p>
                  <Link
                    className="read-more-text"
                    to="/service-details?comp=Web%20Development"
                  >
                    Read More
                  </Link>
                </div>
              </div>
              <div
                className="single-work-process-inner"
                style={{ height: "420px" }}
              >
                <div className="thumb mb-3">
                  <img
                    src="assets/icons/mobile.png"
                    alt="img"
                    style={{ height: "62px" }}
                  />
                </div>
                <div className="details">
                  <h5 className="mb-3">Mobile Development</h5>
                  <p className="content mb-3">
                    We develop intuitive mobile apps for iOS and Android,
                    focusing on user experience, performance, and security. Our
                    apps are scalable to grow with your business, and we offer
                    continuous updates and support to keep your app engaging and
                    aligned with the latest technology.
                  </p>
                  <Link
                    className="read-more-text"
                    to="/service-details?comp=Mobile%20Development"
                  >
                    Read More
                  </Link>
                </div>
              </div>
              <div
                className="single-work-process-inner"
                style={{ height: "420px" }}
              >
                <div className="thumb mb-3">
                  <img src="assets/icons/cloud solution.png" alt="img" />
                </div>
                <div className="details">
                  <h5 className="mb-3">Cloud Solutions</h5>
                  <p className="content mb-3">
                    Our cloud solutions offer scalable, secure services that
                    enhance accessibility and data protection. We manage cloud
                    migration and optimization, ensuring smooth integration and
                    business continuity with robust disaster recovery options
                    for efficient and flexible operations.
                  </p>
                  <Link
                    className="read-more-text"
                    to="/service-details?comp=Cloud%20Solutions"
                  >
                    Read More
                  </Link>
                </div>
              </div>
              <div
                className="single-work-process-inner"
                style={{ height: "420px" }}
              >
                <div className="thumb mb-3" style={{ height: "64px" }}>
                  <img
                    src="assets/icons/erp.png"
                    alt="img"
                    style={{ height: "62px" }}
                  />
                </div>
                <div className="details">
                  <h5 className="mb-3">Enterprise Solutions</h5>
                  <p className="content mb-3">
                    Our enterprise integration services streamline your
                    operations by connecting your software systems, enhancing
                    efficiency and data flow. We provide scalable solutions that
                    support collaboration and growth, reducing redundancy and
                    improving outcomes.
                  </p>
                  <Link
                    className="read-more-text"
                    to="/service-details?comp=Enterprise%20Solutions"
                  >
                    Read More
                  </Link>
                </div>
              </div>
              <div
                className="single-work-process-inner"
                style={{ height: "420px" }}
              >
                <div className="thumb mb-3" style={{ height: "64px" }}>
                  <img
                    src="/assets/icons/lead-generation.png"
                    alt="img"
                    style={{ height: "62px" }}
                  />
                </div>
                <div className="details">
                  <h5 className="mb-3">Lead Generation</h5>
                  <p className="content mb-3">
                    We generate high-quality leads through targeted campaigns
                    and data-driven strategies. Our approach optimizes your
                    sales pipeline, increasing conversion rates and driving
                    growth. We focus on attracting and engaging ideal prospects
                    to maximize your ROI.
                  </p>
                  <Link
                    className="read-more-text"
                    to="/service-details?comp=Lead%20Generation"
                  >
                    Read More
                  </Link>
                </div>
              </div>
              <div
                className="single-work-process-inner"
                style={{ height: "420px" }}
              >
                <div className="thumb mb-3" style={{ height: "64px" }}>
                  <img
                    src="assets/icons/digital-marketing.png"
                    alt="img"
                    style={{ height: "62px" }}
                  />
                </div>
                <div className="details">
                  <h5 className="mb-3">Digital Marketing</h5>
                  <p className="content mb-3">
                    We enhance your online presence with SEO, PPC, content
                    marketing, and social media. Our targeted campaigns drive
                    traffic, increase conversions, and engage your audience
                    effectively. We use data-driven strategies to ensure
                    optimized performance and measurable results.
                  </p>
                  <Link
                    className="read-more-text"
                    to="/service-details?comp=Digital%20Marketing"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaFour;
