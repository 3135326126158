import React from "react";
// import Breadcrumb from "../components/Breadcrumb";
import TeamAreaGroup from "../components/TeamAreaGroup";
import NavbarThree from "../components/NavbarThree";
import FooterThree from "../components/FooterThree";

const Team = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />
      {/* Breadcrumb */}
      {/* <Breadcrumb title={"Team"} /> */}

      {/* Team Area Group */}
      <TeamAreaGroup />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default Team;
