import React from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import NavbarThree from "../components/NavbarThree";
import WorkProcessOne from "../components/WorkProcessOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FooterThree from "../components/FooterThree";

const About = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />

      {/* Navigation Bar */}
      {/* <Breadcrumb title={"About Us"} /> */}

      {/* About Area One */}
      <AboutAreaOne />

      {/* ServiceAreaOne */}
      {/* <ServiceAreaOne /> */}

      {/* FAQ Area One */}
      {/* Team Area One */}
      {/* <TeamAreaOne /> */}
      <WorkProcessOne />

      {/* Counter Area One */}
      <CounterAreaOne />

      {/* Contact Area One */}
      {/* <ContactAreaOne /> */}

      {/* Work Process One */}
      <FaqAreaTwo />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default About;
