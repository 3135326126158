import React from "react";

const DigitalMarketing = () => {
  return (
    <>
      <div className="service-area py-4">
        <div className="container">
          <div className="row">
            <h2>Digital Marketing</h2>
            <p>
              At CivicSols, our digital marketing services help you build a
              strong online presence and connect with your target audience. We
              offer a full suite of services, including SEO, social media
              marketing, content creation, and PPC advertising, to drive
              traffic, generate leads, and increase sales.
            </p>
            <h4>Key Offerings:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">
                Search Engine Optimization <span>(SEO)</span>
              </li>
              <li className="fw-bold">
                Pay-Per-Click Advertising <span>(PPC)</span>{" "}
              </li>
              <li className="fw-bold">Social Media Marketing</li>
              <li className="fw-bold">Content Marketing </li>
              <li className="fw-bold">Email Marketing Campaigns </li>
              <li className="fw-bold">Analytics and Reporting </li>
            </ul>
            <h4>Benefits:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">Increased Visibility:</li>
              <p>
                Our digital marketing strategies are designed to enhance your
                online presence, making it easier for potential customers to
                find you. By improving your visibility on search engines and
                social media platforms, we help drive more traffic to your
                website.
              </p>
              <li className="fw-bold">Targeted Campaigns:</li>
              <p>
                We create highly targeted marketing campaigns that reach your
                ideal audience with personalized messaging. This targeted
                approach increases the effectiveness of your campaigns, leading
                to higher engagement and better conversion rates.
              </p>
              <li className="fw-bold">Higher Engagement:</li>
              <p>
                Our strategies focus on building strong connections with your
                audience through engaging content and interactive campaigns. By
                fostering meaningful interactions, we help you build a loyal
                customer base and improve brand loyalty.
              </p>
              <li className="fw-bold">SEO Optimization:</li>
              <p>
                We implement SEO best practices to improve your website’s
                ranking on search engines, driving more organic traffic. This
                includes optimizing your site’s content, structure, and
                technical aspects to enhance its visibility and relevance.
              </p>
              <li className="fw-bold">Content Creation:</li>
              <p>
                We create compelling and relevant content that resonates with
                your target audience, driving engagement and building trust.
                This includes blog posts, social media updates, videos, and
                other forms of content that align with your brand’s voice and
                objectives.
              </p>
              <li className="fw-bold">Multi-Channel Marketing:</li>
              <p>
                Our multi-channel approach ensures your brand reaches customers
                across various platforms, including social media, email, and
                search engines. This broad reach helps maximize exposure and
                ensures that your marketing efforts are well-rounded and
                effective.
              </p>
              <li className="fw-bold">Data-Driven Decisions:</li>
              <p>
                We use advanced analytics to monitor campaign performance and
                make data-driven decisions to optimize results. This ensures
                that your marketing strategies are continuously refined for
                maximum effectiveness and ROI.
              </p>
              <li className="fw-bold">Measurable Results:</li>
              <p>
                We focus on delivering measurable results, tracking key metrics
                such as conversions, engagement, and ROI. This transparency
                allows you to see the direct impact of your marketing efforts
                and make informed decisions about future strategies.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalMarketing;
