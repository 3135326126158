import React from "react";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import NavbarThree from "../components/NavbarThree";
import FooterThree from "../components/FooterThree";

const Service = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />
      {/* Navigation Bar */}
      {/* <Breadcrumb title={"Service"} /> */}

      {/* Service Area One */}
      <ServiceAreaGroup />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default Service;
