import React from "react";
import { useNavigate } from "react-router-dom";
import serviceList from "../scripts/serviceList";

const ServiceAreaGroup = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-100">
        <img
          className="position-bottom-left top_image_bounce"
          src="assets/img/icon/4.png"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Our Services </h6>
            <h2 className="title">
              Shaping Your Vision, <span> Delivering Success.</span>
            </h2>
          </div>
          <div className="row">
            {serviceList.map((data, index) => (
              <div
                className="col-lg-4 col-md-6"
                style={{ minHeight: "540px" }}
                key={index}
              >
                <div className="single-service-inner text-center">
                  <div className="thumb">
                    <img src={data.img} alt="img" />
                  </div>
                  <div className="details">
                    <h5
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/service-details?comp=${data.title}`)
                      }
                    >
                      {data.title}
                    </h5>
                    <p>{data.des}</p>

                    <h6
                      className="btn btn-border-base"
                      onClick={() =>
                        navigate(`/service-details?comp=${data.title}`)
                      }
                    >
                      Read More
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaGroup;
