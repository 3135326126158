let serviceList = [
  {
    title: "Web Development",
    des: "We design and develop responsive, high-performance websites tailored to your brand. Our solutions include everything from landing pages to complex web applications, ensuring a user-friendly experience with optimal security and scalability.",
    img: "assets/icons/webDev.png",
  },
  {
    title: "Mobile Development",
    des: "We create innovative, user-centric mobile apps for iOS and Android, leveraging the latest technologies to deliver seamless, high-performing experiences. Our solutions are designed to meet your business goals and engage your target audience.",
    img: "assets/icons/mobile.png",
  },
  {
    title: "Cloud Solutions",
    des: "We offer scalable, innovative cloud solutions to get optimize your IT infrastructure, enhance data management, and improve collaboration. Our services ensure secure, efficient, and cost effective cloud environments tailored to your business needs.",
    img: "assets/icons/cloud solution.png",
  },
  {
    title: "Enterprise Solutions",
    des: "We provide robust enterprise solutions to streamline your operations, enhance efficiency, and drive growth. Our services include custom software development, system integration, and comprehensive support for large-scale business needs.",
    img: "assets/icons/erp.png",
  },
  {
    title: "Lead Generation",
    des: "Boost your sales pipeline with our targeted lead generation services. We use advanced techniques and tools to identify and capture high-quality leads that are likely to convert.Our strategies are designed to maximize your ROI and drive business growth.",
    img: "assets/icons/lead-generation.png",
  },

  {
    title: "Digital Marketing",
    des: "Enhance your online presence and reach your target audience with our comprehensive digital marketing services. We offer a range of solutions to help you build brand awareness, engage with your customers, and drive conversions.",
    img: "assets/icons/digital-marketing.png",
  },
];

export default serviceList;
