import React from "react";

const LeadGeneration = () => {
  return (
    <>
      <div className="service-area py-4">
        <div className="container">
          <div className="row">
            <h2>Lead Generation</h2>
            <p>
              At CivicSols, we are designed to help your business attract and
              convert high-quality leads. We utilize a strategic mix of digital
              marketing, content creation, and data analytics to target the
              right audience and drive measurable results.
            </p>
            <h4>Key Offerings:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">B2B Lead Generation</li>
              <li className="fw-bold">B2C Lead Generation </li>
              <li className="fw-bold">Email Marketing Campaigns</li>
              <li className="fw-bold">Social Media Lead Generation </li>
              <li className="fw-bold">Data-Driven Lead Scoring </li>
            </ul>
            <h4>Benefits:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">Targeted Campaigns:</li>
              <p>
                We create highly targeted lead generation campaigns that focus
                on reaching your ideal customers with precision. By leveraging
                data-driven insights, we ensure that your marketing efforts are
                directed at the right audience, maximizing impact and conversion
                rates.
              </p>
              <li className="fw-bold">Quality Leads:</li>
              <p>
                Our strategies are designed to attract high-quality leads who
                are more likely to convert into loyal customers. By focusing on
                quality over quantity, we help you achieve better sales outcomes
                and improve your return on investment (ROI).
              </p>
              <li className="fw-bold">Data-Driven Strategies:</li>
              <p>
                We use advanced analytics to monitor and optimize lead
                generation campaigns, continuously improving performance. This
                data-driven approach allows us to refine strategies in
                real-time, ensuring that your campaigns remain effective and
                relevant.
              </p>
              <li className="fw-bold">Cost-Effective: </li>
              <p>
                Our lead generation services are designed to be cost-efficient,
                providing you with the best possible return on investment. By
                targeting the right audience and optimizing campaigns, we help
                you achieve more with less, reducing overall marketing costs.
              </p>
              <li className="fw-bold">Multi-Channel Approach:</li>
              <p>
                We leverage a variety of channels, including social media,
                email, content marketing, and PPC, to engage potential leads.
                This multi-channel approach ensures that you reach your audience
                wherever they are, increasing the chances of generating leads.
              </p>
              <li className="fw-bold">Scalable Solutions:</li>
              <p>
                Our lead generation strategies are scalable, allowing you to
                increase or decrease efforts based on your business needs. This
                flexibility ensures that you can adapt to changing market
                conditions and business goals without overcommitting resources.
              </p>
              <li className="fw-bold">Conversion Optimization: </li>
              <p>
                We focus on optimizing the lead conversion process, ensuring
                that once leads are generated, they are effectively nurtured and
                converted into customers. This includes personalized follow-ups,
                targeted messaging, and tailored offers.
              </p>
              <li className="fw-bold">Continuous Improvement:</li>
              <p>
                We regularly review and refine lead generation strategies to
                ensure they remain effective and aligned with your business
                objectives. This commitment to continuous improvement helps you
                stay ahead of the competition and consistently achieve your
                sales goals.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadGeneration;
