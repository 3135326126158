import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  Transforming Businesses Through <span> Software </span>{" "}
                  Innovation
                </h2>
                <p className="content mb-4 mb-xl-5">
                  Welcome to Civicsols, where innovation meets excellence in
                  software development. Founded on the principles of quality,
                  reliability, and client-centric solutions, we are dedicated to
                  transforming your digital vision into reality.We are a team of
                  passionate technologists and creative thinkers committed to
                  delivering cutting-edge software solutions. Our expertise
                  spans a diverse range of industries, allowing us to craft
                  bespoke solutions that drive business success.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/icons/vision.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Our Vision</h5>
                        <p>
                          Our vision at <span> Civicsols </span> is to be a
                          global leader in software innovation, transforming
                          ideas into impactful solutions that empower businesses
                          and inspire digital transformation. We aim to shape
                          the future of technology with integrity, creativity,
                          and excellence.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/icons/goal.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Our Goal</h5>
                        <p>
                          At <span> Civicsols </span> our goal is to empower
                          businesses through innovative software solutions that
                          drive growth, enhance efficiency, and ensure lasting
                          success. We strive to deliver exceptional value to our
                          clients by combining cutting-edge technology with a
                          customer-centric approach.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-5">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/icons/mission.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Our Mission</h5>
                        <p>
                          Our mission is to empower businesses through
                          innovative technology solutions that enhance
                          efficiency, foster growth, and ensure long-term
                          success. We strive to build lasting relationships with
                          our clients by consistently delivering exceptional
                          results.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-5">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/icons/approach.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Our Approach</h5>
                        <p>
                          We follow a client-focused approach, ensuring that we
                          understand your unique requirements and business
                          goals. Our agile development process guarantees
                          flexibility, timely delivery, and continuous
                          improvement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-5">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-1 top_image_bounce"
                  src="assets/img/about/2.png"
                  alt="img"
                />
                <img
                  className="animate-img-2 left_image_bounce"
                  src="assets/img/about/3.png"
                  alt="img"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                />
                <img
                  className="main-img"
                  src="assets/img/about/1.png"
                  alt="img"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
