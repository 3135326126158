import React from "react";
import AboutAreaFour from "../components/AboutAreaFour";
import BannerFour from "../components/BannerFour";
import FaqAreaTwo from "../components/FaqAreaTwo";
import FooterThree from "../components/FooterThree";
import NavbarThree from "../components/NavbarThree";
import ProjectAreaOne from "../components/ProjectAreaOne";
import ServiceAreaFour from "../components/ServiceAreaFour";
import TeamAreaTwo from "../components/TeamAreaTwo";
import TestimonialThree from "../components/TestimonialThree";
import CounterAreaOne from "../components/CounterAreaOne";
import { Link } from "react-router-dom";

const HomeFour = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />
      {/* Banner Four */}
      <BannerFour />

      {/* About Area Four */}
      <AboutAreaFour />

      {/* Service Area four */}
      <ServiceAreaFour />

      {/* FAQ Area Two */}
      <FaqAreaTwo />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link
          className="mb-5 btn btn-border-base"
          data-aos="fade-top"
          data-aos-delay="400"
          data-aos-duration="1500"
          to="/contact"
        >
          Contact us
        </Link>
      </div>
      {/* Team Area Two */}
      <TeamAreaTwo />

      {/* Career Area One */}
      {/* <CareersAreaOne /> */}

      {/* Project Area One */}
      <ProjectAreaOne />

      {/* Contact Area Four */}
      {/* <ContactAreaOne /> */}
      <div className="mt-lg-5">
        <CounterAreaOne />
      </div>
      {/* Testimonial Three */}
      <TestimonialThree />

      {/* Counter Area Four */}
      {/* <CounterAreaFour /> */}

      {/* Brand Area One */}
      {/* <BrandAreaOne /> */}

      {/* Footer Three */}
      <FooterThree />
    </>
  );
};

export default HomeFour;
