import React from "react";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";

const TestimonialThree = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {/* =================== Testimonial Three Start ===================*/}
      <div
        className="testimonial-area testimonial-area_3 bg-cover py-5"
        style={{ backgroundImage: 'url("./assets/img/bg/13.png")' }}
      >
        <div className="container pd-bottom-100">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center">
                <h6 className="sub-title">Testimonial</h6>
                <h2 className="title">
                  What Client <span>Think</span> About Us?
                </h2>
              </div>
            </div>
          </div>
          <div className="testimonial-slider-2 owl-carousel slider-control-dots">
            <Slider {...settings}>
              <div className="item">
                <div className="single-testimonial-inner style-3">
                  <div className="row">
                    {/* <div className="col-md-5">
                      <div className="thumb">
                        <div className="shadow-img">
                          <img src="assets/img/testimonial/0.png" alt="img" />
                        </div>
                        <img
                          className="main-img"
                          src="assets/img/testimonial/10.png"
                          alt="img"
                        />
                      </div>
                    </div> */}
                    <div className="align-self-center">
                      <div className="details">
                        <img
                          className="quote"
                          src="assets/img/testimonial/01.png"
                          alt="img"
                        />
                        <p className="designation mb-0">
                          A very human gentleman. I will give them a 5 stars
                          across the board, despite occasional delays beyond his
                          control and due to his dedication to make the project
                          as perfect as possible. He is very sensitive to the
                          client's needs, does his job conscientiously, and
                          leaves no stone unturned to produce an outstanding
                          result. It was a pleasure working with him.
                        </p>
                        <h6 className="mb-0 mt-3">Armen Melikian </h6>
                        <h6>USA</h6>

                        <div className="ratting-inner mt-3">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="single-testimonial-inner style-3">
                  <div className="row">
                    <div className=" align-self-center">
                      <div className="details">
                        <img
                          className="quote"
                          src="assets/img/testimonial/01.png"
                          alt="img"
                        />
                        <p className="designation mb-0">
                          We had an outstanding experience working with this
                          team on our brand email address-gathering project.
                          They successfully obtained high-quality prospects,
                          focusing on Brand, Content, Marketing managers, or
                          higher. Their expertise in research, online
                          prospecting, and data accuracy ensured precise and
                          well-organized results. They demonstrated
                          self-motivation, met deadlines, and prioritized Swiss
                          prospects as requested. We highly recommend them for
                          similar missions and look forward to future
                          collaborations.
                        </p>
                        <h6 className="mb-0 mt-3">Lukas Quintero</h6>
                        <h6>Switzerland</h6>
                        <div className="ratting-inner mt-3">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="single-testimonial-inner style-3">
                  <div className="row">
                    <div className=" align-self-center">
                      <div className="details">
                        <img
                          className="quote"
                          src="assets/img/testimonial/01.png"
                          alt="img"
                        />
                        <p className="designation mb-0">
                          Civicsols team has excelled as a Sales Navigator
                          Experts, demonstrating an impressive ability to
                          leverage LinkedIn's platform for effective
                          prospecting. Their advanced search techniques and data
                          analysis consistently yield high-quality lead lists.
                          Civicsols team collaborative approach and strong
                          communication skills have further enhanced our sales
                          efforts. Their expertise and dedication make them a
                          valuable asset to our team.
                        </p>
                        <h6 className="mb-0 mt-3">Ahmad Rasool</h6>
                        <h6>United Kingdom</h6>
                        <div className="ratting-inner mt-3">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="single-testimonial-inner style-3">
                  <div className="row">
                    <div className=" align-self-center">
                      <div className="details">
                        <img
                          className="quote"
                          src="assets/img/testimonial/01.png"
                          alt="img"
                        />
                        <p className="designation mb-0">
                          A True Master of Lead Generation! Not only did
                          Muhammad excel in generating leads, but he also
                          demonstrated a keen eye for data analysis. His ability
                          to interpret performance metrics and adjust the
                          strategies accordingly resulted in improved lead
                          quality and conversion rates. Thank you, Muhammad, for
                          your exceptional work and commitment to excellence. We
                          look forward to collaborating with you on future
                          projects as we continue to expand our business.
                        </p>
                        <h6 className="mb-0 mt-3">Daniel Dartera</h6>
                        <h6>Switzerland</h6>
                        <div className="ratting-inner mt-3">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="single-testimonial-inner style-3">
                  <div className="row">
                    <div className=" align-self-center">
                      <div className="details">
                        <img
                          className="quote"
                          src="assets/img/testimonial/01.png"
                          alt="img"
                        />
                        <p className="designation mb-0">
                          We had an outstanding experience working with this
                          remote worker on our brand email address-gathering
                          project. They successfully obtained high-quality
                          prospects, focusing on Brand, Content, Marketing
                          managers, or higher. Their expertise in research,
                          online prospecting, and data accuracy ensured precise
                          and well-organized results. They demonstrated self-
                          motivation, met deadlines, and prioritized Swiss
                          prospects as requested. We highly recommend them for
                          similar missions and look forward to future
                          collaborations.
                        </p>
                        <h6 className="mb-0 mt-3">Mees Wijnants</h6>
                        <h6>Netherlands</h6>
                        <div className="ratting-inner mt-3">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="single-testimonial-inner style-3">
                  <div className="row">
                    <div className=" align-self-center">
                      <div className="details">
                        <img
                          className="quote"
                          src="assets/img/testimonial/01.png"
                          alt="img"
                        />
                        <p className="designation mb-0">
                          We had the pleasure of collaborating with this
                          freelancer on our project centered around social media
                          content creation. His contribution was truly
                          outstanding as heconsistently delivered engaging and
                          high-quality content across various platforms. He
                          demonstrated a remarkable ability to adapt to our
                          feedback, ensuring that our vision was perfectly
                          translated into the content. His commitment to meeting
                          deadlines and proactive communication made the entire
                          process seamless. We highly commend his work and
                          eagerly look forward to the opportunity to engage in
                          future projects together.
                        </p>
                        <h6 className="mb-0 mt-3">Rosie</h6>
                        <h6>Australia</h6>
                        <div className="ratting-inner mt-3">
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* =================== Testimonial Three End ===================*/}
    </>
  );
};

export default TestimonialThree;
