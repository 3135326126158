import React from "react";
// import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const TeamAreaTwo = () => {
  var settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {/*====================== team area start ======================*/}
      <div
        className="team-area bg-relative py-5"
        style={{ backgroundImage: 'url("./assets/img/bg/12.png")' }}
      >
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">MEET OUR EXPERTS</h6>
            <h2 className="title text-white">
              Your Partner In <span>Digital</span> Success
            </h2>
          </div>
          <div className="row">
            <Slider {...settings}>
              <div className="col-lg-4 col-md-6 p-1">
                <div className="single-team-inner style-2 text-center">
                  <div className="thumb" style={{ height: "320px" }}>
                    <img
                      className="img-fluid"
                      src="assets/images/sadeed.jpg"
                      alt="img"
                      style={{ padding: "15px" }}
                    />
                  </div>
                  <div className="details">
                    <div className="details-inner">
                      <h5>
                        <Link to="/team">Sadeed ur Raheem</Link>
                      </h5>
                      <p>CEO</p>
                      {/* <ul className="team-social-inner mt-3 mb-1">
                      <li>
                        <Link to="#">
                          <FaFacebookF />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <FaTwitter />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <FaInstagram />
                        </Link>
                      </li>
                    </ul> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-1">
                <div className="single-team-inner style-2 text-center">
                  <div className="thumb" style={{ height: "320px" }}>
                    <img src="assets/images/person.png" alt="img" />
                  </div>
                  <div className="details">
                    <div className="details-inner">
                      <h5>
                        <Link to="/team">Shajeel ur Rehman</Link>
                      </h5>
                      <p>CTO</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-1">
                <div className="single-team-inner style-2 text-center">
                  <div className="thumb" style={{ height: "320px" }}>
                    <img src="assets/images/hamood.jpeg" alt="img" />
                  </div>
                  <div className="details">
                    <div className="details-inner">
                      <h5>
                        <Link to="/team">Hamood ur Rehman</Link>
                      </h5>
                      <p>Agile Project Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-1">
                <div className="single-team-inner style-2 text-center">
                  <div className="thumb" style={{ height: "320px" }}>
                    <img src="assets/images/person.png" alt="img" />
                  </div>
                  <div className="details">
                    <div className="details-inner">
                      <h5>
                        <Link to="/team">Talha Khaliq</Link>
                      </h5>
                      <p>Sales & Growth Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-1">
                <div className="single-team-inner style-2 text-center">
                  <div className="thumb" style={{ height: "320px" }}>
                    <img src="assets/images/usama.jpeg" alt="img" />
                  </div>
                  <div className="details">
                    <div className="details-inner">
                      <h5>
                        <Link to="/team">Muhammad Usama</Link>
                      </h5>
                      <p>Sales & Marketing Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-1">
                <div className="single-team-inner style-2 text-center">
                  <div className="thumb" style={{ height: "320px" }}>
                    <img src="assets/images/waqar1.png" alt="img" />
                  </div>
                  <div className="details">
                    <div className="details-inner">
                      <h5>
                        <Link to="/team">Waqar Afzal</Link>
                      </h5>
                      <p>Business Development Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-1">
                <div className="single-team-inner style-2 text-center">
                  <div className="thumb" style={{ height: "320px" }}>
                    <img src="assets/images/imran.jpg" alt="img" />
                  </div>
                  <div className="details">
                    <div className="details-inner">
                      <h5>
                        <Link to="/team">Imran Ahmad</Link>
                      </h5>
                      <p>Business Development Manager</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-1">
                <div className="single-team-inner style-2 text-center">
                  <div className="thumb" style={{ height: "320px" }}>
                    <img src="assets/images/person.png" alt="img" />
                  </div>
                  <div className="details">
                    <div className="details-inner">
                      <h5>
                        <Link to="/team">Subhan Khan</Link>
                      </h5>
                      <p>Technical Lead</p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaTwo;
