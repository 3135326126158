import React, { useState } from "react";
// import { FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";

const NavbarThree = () => {
  const [active, setActive] = useState(false);
  // const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  // const searchActive = () => {
  //   setSearchShow(!searchShow);
  //   console.log("hell");
  // };

  // Control sidebar navigation
  // let items = document.querySelectorAll(".menu-item-has-children > a");
  // for (let i in items) {
  //   if (items.hasOwnProperty(i)) {
  //     items[i].onclick = function () {
  //       this.parentElement
  //         .querySelector(".sub-menu")
  //         .classList.toggle("active");
  //       this.classList.toggle("open");
  //     };
  //   }
  // }
  return (
    <>
      {/* search popup start*/}
      {/* <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div> */}
      {/* search popup end*/}
      {/* <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id="body-overlay"
      ></div> */}
      {/* ==================== Navbar Two Start ====================*/}
      <nav className="navbar navbar-area navbar-area_3 navbar-area-2 fixed-top  navbar-expand-lg bg-white">
        <div className="container nav-container custom-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none "
              }
              style={{ marginBlock: "32px" }}
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo" style={{ maxHeight: "100px" }}>
            <Link to="/">
              <img
                src="assets/img/logo.png"
                alt="img"
                style={{ maxWidth: "100px" }}
              />
            </Link>
          </div>
          {/* <div className="nav-right-part nav-right-part-mobile">
            <span className="search-bar-btn" onClick={searchActive}>
              <FaSearch />
            </span>
          </div> */}
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/service">Service</Link>
              </li>
              <li>
                <Link to="/team">Team</Link>
              </li>
              <li>
                <Link to="/project">Portfolio</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center">
            <Link className="btn btn-border-base" to="/contact">
              Refer Quote
            </Link>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarThree;
