import React from "react";

const CloudSolution = () => {
  return (
    <>
      <div className="service-area py-4">
        <div className="container">
          <div className="row">
            <h2>Cloud Solution</h2>
            <p>
              At CivicSols, we provide comprehensive cloud solutions that
              empower your business with greater flexibility, scalability, and
              security. Whether you're looking to migrate to the cloud, optimize
              your existing infrastructure, or develop cloud-native
              applications, we offer tailored solutions to meet your needs.
            </p>
            <h4>Key Offerings:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">Cloud Strategy and Consulting</li>
              <li className="fw-bold">Cloud Migration Services </li>
              <li className="fw-bold">Cloud Infrastructure Management</li>
              <li className="fw-bold">DevOps and Automation </li>
              <li className="fw-bold">Security and Compliance </li>
            </ul>
            <h4>Benefits:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">Scalability:</li>
              <p>
                Our cloud solutions are designed to scale effortlessly with your
                business, allowing you to adjust resources as needed without
                disruption. This flexibility ensures that you can handle growth
                and fluctuations in demand without overpaying for unused
                capacity.
              </p>
              <li className="fw-bold">Cost Efficiency:</li>
              <p>
                By utilizing cloud resources, you only pay for what you use,
                optimizing operational costs and improving your bottom line.
                This model allows you to avoid the high upfront costs of
                traditional IT infrastructure, making it easier to manage
                budgets.
              </p>
              <li className="fw-bold">Security:</li>
              <p>
                We implement advanced security protocols in our cloud solutions,
                including encryption, multi-factor authentication, and regular
                security audits. This protects your data from breaches and
                ensures compliance with industry regulations.
              </p>
              <li className="fw-bold">Accessibility:</li>
              <p>
                Cloud solutions enable you to access your data and applications
                from anywhere, providing flexibility and supporting remote work.
                This ensures that your team can stay productive and connected,
                regardless of their location.
              </p>
              <li className="fw-bold">Disaster Recovery:</li>
              <p>
                Our cloud solutions include robust disaster recovery options,
                ensuring that your data is backed up and can be quickly restored
                in the event of an outage or disaster. This minimizes downtime
                and helps maintain business continuity.
              </p>
              <li className="fw-bold">Integration:</li>
              <p>
                We seamlessly integrate cloud services with your existing IT
                infrastructure, allowing for smooth operations and enhanced
                functionality. This integration ensures that your cloud
                environment works harmoniously with your on-premises systems.
              </p>
              <li className="fw-bold">Performance:</li>
              <p>
                We optimize cloud environments for high performance, ensuring
                fast access to your data and applications. This includes
                minimizing latency, maximizing uptime, and ensuring that your
                systems run efficiently.
              </p>
              <li className="fw-bold">Ongoing Management:</li>
              <p>
                We provide continuous monitoring and management of your cloud
                infrastructure, keeping it secure, up-to-date, and running
                smoothly. Our team handles maintenance, updates, and
                troubleshooting, so you can focus on your core business.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CloudSolution;
