import React from "react";
import ProjectAreaOne from "../components/ProjectAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import NavbarThree from "../components/NavbarThree";
import FooterThree from "../components/FooterThree";

const ProjectTwo = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />
      {/* Breadcrumb */}
      {/* <Breadcrumb title={"Project 02"} /> */}

      {/* ProjectAreaOne */}
      <ProjectAreaOne />

      {/* TeamAreaOne */}
      {/* <TeamAreaOne /> */}

      {/* Counter Area One */}
      <CounterAreaOne />

      {/* Contact Area One */}
      {/* <div className="pd-bottom-120">
        <ContactAreaOne />
      </div> */}

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default ProjectTwo;
