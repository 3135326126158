import React from "react";

const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className="work-process-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Our Strategic Approach</h6>
            <h2 className="title">
              <span>Agile Development,</span> Precise Solutions.
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/icons/planning.png" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 01</p>
                  <h5 className="mb-3">Discovery and Planning</h5>
                  <p className="content">
                    We begin by understanding your business objectives and
                    technical requirements. Together, we prioritize features and
                    create a dynamic product backlog, setting the stage for a
                    successful project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/icons/Iteratuve.png" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 02</p>
                  <h5 className="mb-3">Iterative Development</h5>
                  <p className="content">
                    Our development is organized into short, focused sprints.
                    Each sprint delivers a functional piece of the software,
                    allowing for regular feedback and continuous refinement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/icons/collaboration.png" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 03</p>
                  <h5 className="mb-3">Continuous Collaboration</h5>
                  <p className="content">
                    We maintain open communication with you throughout the
                    process. Regular sprint reviews and daily stand-ups keep
                    everyone aligned and ensure that the project stays on track.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/icons/quality.png" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 04</p>
                  <h5 className="mb-3">Quality Assurance</h5>
                  <p className="content">
                    Testing is integral to our process. We conduct thorough
                    testing during each sprint to catch and resolve issues
                    early, ensuring the final product is robust and reliable.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/icons/adaption.png" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 05</p>
                  <h5 className="mb-3">Adaptation and Scaling</h5>
                  <p className="content">
                    Our Agile approach allows us to adapt to changes quickly. As
                    new insights or requirements emerge, we adjust our plan to
                    keep the project aligned with your evolving needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/icons/support.png" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 06</p>
                  <h5 className="mb-3">Final Delivery and Support </h5>
                  <p className="content">
                    We deliver the completed software on time and within budget,
                    with all agreed-upon features. We provide ongoing support
                    and maintenance to ensure the software continues to perform
                    optimally.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
