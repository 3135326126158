import React from "react";

const FaqAreaTwo = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div className="faq-area ">
        <div className="container">
          <div className="row py-5">
            <div className="col-xl-12 col-lg-12 align-self-center">
              <div className="section-title mb-0 mt-2 mt-lg-0">
                <h4 className="title pd-bottom-20">
                  <span> FAQ's </span>
                </h4>
                <h2 className="title">
                  Let’s Explore Your Most Fidgeting <span>Question</span>
                </h2>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How do I get started with a project?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      You can start by contacting us through our contact form,
                      email, or phone. We will discuss your project requirements
                      and provide a tailored solution for your business needs.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How do you choose the right development platform for a
                      project?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We select the right development platform by thoroughly
                      understanding your business goals, target audience, and
                      specific project requirements. We evaluate various
                      technology stacks based on their capabilities,
                      scalability, security features, and alignment with your
                      needs. Future growth, integration with existing systems,
                      cost implications, and our team’s expertise are also
                      considered. By adhering to industry best practices and
                      standards, we ensure the chosen platform provides a robust
                      and reliable foundation, leading to a successful project
                      outcome.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What industries do you specialize in?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We have experience working across various industries such
                      as healthcare, finance, retail, education, and more. Our
                      versatile team is capable of adapting to the unique needs
                      of different sectors.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What is your development process?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Our development process follows agile methodologies,
                      including requirement analysis, planning, design,
                      development, testing, deployment, and maintenance. This
                      ensures flexibility and timely delivery of high-quality
                      solutions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      How do you ensure the quality of your software?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We follow strict quality assurance processes, including
                      code reviews, automated testing, manual testing, and
                      continuous integration/continuous deployment (CI/CD)
                      pipelines to ensure the highest quality of our software.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      How do you generate leads for businesses?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="collapseSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We use a variety of strategies including email marketing,
                      social media campaigns, content marketing, and targeted
                      advertising to generate high-quality leads that are likely
                      to convert.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      How do you measure the success of digital marketing
                      campaigns?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="collapseSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We use various metrics and analytics tools to track the
                      performance of our campaigns. This includes website
                      traffic, conversion rates, engagement metrics, and ROI. We
                      provide regular reports to keep you informed about the
                      progress.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaTwo;
