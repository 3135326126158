import React from "react";

const WebDevelopment = () => {
  return (
    <>
      <div className="service-area py-4">
        <div className="container">
          <div className="row">
            <h2>Web Development</h2>
            <p>
              At CivicSols, we craft responsive, user-centric websites that not
              only represent your brand but also drive business growth. Whether
              you need a simple landing page or a complex web application, our
              team delivers high-performance, secure, and scalable solutions
              tailored to your specific needs.
            </p>
            <h4>Key Offerings:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">Custom Web Application Development</li>
              <li className="fw-bold">E-commerce Solutions </li>
              <li className="fw-bold">CMS Development</li>
              <li className="fw-bold">Responsive Web Design </li>
              <li className="fw-bold">Maintenance and Support </li>
            </ul>
            <h4>Benefits:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">Custom Solutions:</li>
              <p>
                We develop websites tailored to your specific business
                requirements, ensuring they accurately represent your brand and
                meet your goals. Our custom solutions are designed to help you
                stand out in a crowded digital landscape, delivering unique
                features and functionality that cater directly to your target
                audience.
              </p>
              <li className="fw-bold">Responsive Design:</li>
              <p>
                Our websites are fully responsive, meaning they automatically
                adjust to provide an optimal viewing experience on any device,
                whether it’s a desktop, tablet, or smartphone. This ensures that
                your users have a seamless and engaging experience, no matter
                how they access your site.
              </p>
              <li className="fw-bold">SEO Integration:</li>
              <p>
                We incorporate search engine optimization (SEO) best practices
                into the development process, enhancing your website's
                visibility on search engines. This helps drive organic traffic
                to your site, increasing your chances of reaching and converting
                your target audience.
              </p>
              <li className="fw-bold">Fast Loading:</li>
              <p>
                We prioritize performance by optimizing your website's speed,
                ensuring it loads quickly even with heavy content. Fast-loading
                sites improve user experience, reduce bounce rates, and can
                positively impact your search engine rankings.
              </p>
              <li className="fw-bold">Security:</li>
              <p>
                Our development process includes advanced security measures to
                protect your website from vulnerabilities and cyber threats.
                This includes regular updates, encryption, and secure coding
                practices to safeguard your data and maintain user trust.
              </p>
              <li className="fw-bold">Scalability:</li>
              <p>
                We design websites that are built to scale with your business,
                allowing for easy expansion as your needs evolve. Whether it's
                handling increased traffic or adding new features, our solutions
                are flexible and future-proof.
              </p>
              <li className="fw-bold">User-Friendly CMS:</li>
              <p>
                We implement intuitive content management systems (CMS) like
                WordPress, enabling you to easily manage and update your website
                without needing technical expertise. This empowers you to keep
                your content fresh and relevant.
              </p>
              <li className="fw-bold">Ongoing Support:</li>
              <p>
                We offer continuous support and maintenance services to ensure
                your website remains up-to-date, secure, and fully operational.
                Our team is always available to address any issues and make
                necessary updates.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebDevelopment;
