import React from "react";
import ServiceDetailsArea from "../components/ServiceDetailsArea";
import NavbarThree from "../components/NavbarThree";
import FooterThree from "../components/FooterThree";

const ServiceDetails = () => {
  const params = new URLSearchParams(document.location.search);
  const component = params.get("comp");
  console.log("comp", component);
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />
      {/* Navigation Bar */}
      {/* <Breadcrumb title={"Service Details"} /> */}

      {/* Service Details Area */}
      <ServiceDetailsArea data={component} />

      {/* Footer One */}
      <FooterThree />
    </>
  );
};

export default ServiceDetails;
