import React from "react";
import ContactMain from "../components/ContactMain";
import NavbarThree from "../components/NavbarThree";
import FooterThree from "../components/FooterThree";

const Contact = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />
      {/* Navigation Bar */}
      {/* <Breadcrumb title={"Contact"} /> */}

      {/* Contact Main */}
      <ContactMain />

      <FooterThree />
    </>
  );
};

export default Contact;
