import React from "react";

const MobileDevelopment = () => {
  return (
    <>
      <div className="service-area py-4">
        <div className="container">
          <div className="row">
            <h2>Mobile Development</h2>
            <p>
              At CivicSols, we focus on creating intuitive, high-performance
              apps that engage users and drive business growth. We specialize in
              developing apps for iOS, Android, and cross-platform solutions,
              delivering a seamless user experience across all devices.
            </p>
            <h4>Key Offerings:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">Mobile App UI/UX Design</li>
              <li className="fw-bold">iOS and Android App Development</li>
              <li className="fw-bold">
                Cross-Platform App Development{" "}
                <span>(React Native, Flutter)</span>{" "}
              </li>
              <li className="fw-bold">App Testing and Quality Assurance </li>
              <li className="fw-bold">Maintenance and Updates </li>
            </ul>
            <h4>Benefits:</h4>
            <ul style={{ paddingLeft: "30px" }}>
              <li className="fw-bold">Cross-Platform Development:</li>
              <p>
                We develop mobile apps for iOS, Android, and other platforms,
                ensuring your app reaches the widest possible audience. By using
                cross-platform frameworks, we deliver consistent user
                experiences across devices while optimizing development time and
                cost.
              </p>
              <li className="fw-bold">User-Centric Design:</li>
              <p>
                Our mobile apps are designed with the user in mind, featuring
                intuitive interfaces that enhance engagement and satisfaction.
                We focus on creating seamless interactions that keep users
                coming back, improving retention and driving business growth.
              </p>
              <li className="fw-bold">Performance Optimization:</li>
              <p>
                We optimize our apps for speed, efficiency, and reliability,
                ensuring they perform well even under heavy use. This includes
                minimizing load times, reducing battery consumption, and
                ensuring smooth transitions and animations.
              </p>
              <li className="fw-bold">Scalability:</li>
              <p>
                Our mobile apps are built to grow with your user base, allowing
                for easy scaling as your business expands. We design apps that
                can handle increased traffic and feature additions without
                compromising performance.
              </p>
              <li className="fw-bold">Security:</li>
              <p>
                We integrate robust security features into our apps, including
                encryption, secure authentication, and regular updates to
                protect against vulnerabilities. This ensures that user data is
                safe and that your app complies with privacy regulations.
              </p>
              <li className="fw-bold">Integration:</li>
              <p>
                Our apps seamlessly integrate with your existing systems,
                providing a cohesive experience across all platforms. This
                integration enhances functionality and ensures that your app
                fits into your overall digital strategy.
              </p>
              <li className="fw-bold">Regular Updates:</li>
              <p>
                We provide ongoing updates to keep your app aligned with the
                latest technological advancements and user expectations. This
                includes adding new features, improving performance, and
                addressing any emerging issues.
              </p>
              <li className="fw-bold">End-to-End Support:</li>
              <p>
                From initial concept through development, launch, and
                post-launch maintenance, we offer comprehensive support to
                ensure your app’s success. Our team is with you every step of
                the way to provide guidance and resolve any challenges.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileDevelopment;
